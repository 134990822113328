import React, {  } from 'react'
import { Technology } from "src/assembled"
import { Footer } from "src/components"
import { SEO } from "src/components/utils"


export default function TechnologyIndex({ location }) {

    // seo
    const seo = <SEO
    title="Tecgnology"
    description="인공지능을 기반으로한 디어젠의 Target-Discovery 기술과 Drug-Design 기술을 소개합니다." 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <Technology location={location} />
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}
